

import { Text } from "@chakra-ui/layout";
import { Spacer } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack,VStack } from "@chakra-ui/layout"; 
import eventBus from "../eventBus";
import { getMONEDADivisa } from './glovar'
import { getMarketCAP } from '../../utils/Common';
//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

function showElement(element) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showElement", { el: element });
  //console.log('commmm pos:'+pos)
  } 

  export default ({ element, name, price, diff, pordiff, moneda, elementCriteri, filterList, modeCompact, superCompact }) => {

    var NO_CRITERI=true;

    var DISTANCIA_M50=false; 
    var DISTANCIA_M200=false; 
    var INCREMENTO_VOL=false; 
    var VAR_52_MIN=false;
    var VAR_52_MAX=false; 
  
    var PER=false; 
    var EPS=false;
    var DIVI_RENTAB=false; 
    var PRICE_TO_BOOK=false; 
    var BOOK_VALUE=false; 
    var RESULTADOS=false;
    var RATING_ANALISTOS=false; 

    var MODE_COMPACT=false;
    var SUPER_COMPACT=false;

    if(modeCompact==true) {
      MODE_COMPACT=true;
    } 

    if(superCompact==true) {
      SUPER_COMPACT=true;
    }

    //console.log(JSON.stringify(element));
    
    let color1="#1FB31F"
    let color2="#FFFFFF"

    var subtitle="subtitle"
    var title=name;
    var percent=Number(diff).toFixed(2);
    var change=Number(pordiff).toFixed(2);
    var loss=Number(diff).toFixed(2);
    var coti=price;
    var loss=(diff<0);

    var simbol="";

    //NO_CRITERI=true; 
    //try { if(elementCriteri[0].label.length>2) { NO_CRITERI=false; }} catch(Exx) {}

    NO_CRITERI=true; 
    try { 
      if(elementCriteri[0].label.length>2) 
      { 
       NO_CRITERI=false; 
  
       for (let i=0;i<elementCriteri.length;i++) 
       {
        switch(elementCriteri[i].value)
        {
         case "fiftyDayAverageChangePercent": DISTANCIA_M50=true; break;
         case "twoHundredDayAverageChangePercent": DISTANCIA_M200=true; break;
         case "averageDailyVolume10Day,averageDailyVolume3Month":  INCREMENTO_VOL=true; break;
         case "fiftyTwoWeekLowChangePercent": VAR_52_MIN=true; break;
         case "fiftyTwoWeekHighChangePercent":VAR_52_MAX=true; break;
  
         case "trailingPE": PER=true; break;
         case "epsTrailingTwelveMonths": EPS=true; break;
         case "trailingAnnualDividendRate,trailingAnnualDividendYield": DIVI_RENTAB=true; break;
         case "priceToBook": PRICE_TO_BOOK=true; break;
         case "bookValue": BOOK_VALUE=true; break;
         case "earningsTimestamp,earningsTimestampStart,earningsTimestampEnd": RESULTADOS=true; break;
         case "averageAnalystRating": RATING_ANALISTOS=true; break;     
       }
      }
    
     }
    } catch(Exx) {}


    try { moneda=getMONEDADivisa(element.currency,element.quoteType); } catch(Exx) {}

    //console.log(JSON.stringify(element)); 
    try {
    if(coti<1) 
    {
      
        var text = coti.toString();
        var index = text.indexOf(".");
        if(index>=1) {
        var index2 = ((text.length - index - 1));
        if(index2>5) { coti=coti.toFixed(5)} 
        //console.log("decimaleeeeeeeeeeees:"+index2)
        }
    }
    }
    catch(Exxx) {}

    //const icon = () => { return(<FiArrowDown />); }

    if(diff>0) {
        color1="#1FB31F"
          color2="#FFFFFF"
          simbol="+"
    }
    else {
        color1="#D61A18"
        color2="#FFFFFF"
    }

    //####################//
    //# Check names      #//
    //####################//
    if(title=="IBEX 35...") {title="IBEX 35"; }
    if(title=="ESTX 50 PR.EUR") { title="EuroStoxx 50"}

    var marketCAP=0;
   try {
    if(element.marketCap>0)
    {
     marketCAP=element.marketCap;
     marketCAP=getMarketCAP(element.marketCap,'es') 
    }
  }
  catch(Exasdad) { }

  
  var symbol="";              try { symbol=element.symbol; } catch(Exxx) {}
  var regularMarketVolume=""; try { regularMarketVolume=element.regularMarketVolume } catch(Exxx) {}
  var trailingPE="";  try { trailingPE=element.trailingPE.toFixed(2) } catch(Exxx) {}
  var epsTrailingTwelveMonths=""; try { epsTrailingTwelveMonths=element.epsTrailingTwelveMonths.toFixed(2) } catch(Exxx) {}
  var priceToBook=""; try { priceToBook=element.priceToBook.toFixed(2) } catch(Exxx) {}
  var trailingAnnualDividendRate="";  try { trailingAnnualDividendRate=element.trailingAnnualDividendRate } catch(Exxx) {}
  var trailingAnnualDividendYield=""; try { trailingAnnualDividendYield=(element.trailingAnnualDividendYield*100).toFixed(2) } catch(Exxx) {}
  
  var dis_m50="";    try { dis_m50=(element.fiftyDayAverageChangePercent*100).toFixed(2) } catch(Exxx) {}
  var dis_m200="";   try { dis_m200=(element.twoHundredDayAverageChangePercent*100).toFixed(2) } catch(Exxx) {}

  var inc_vol="";   try { inc_vol=(((element.averageDailyVolume10Day-element.averageDailyVolume3Month)/element.averageDailyVolume3Month)*100).toFixed(2) } catch(Exxx) {}
  var var_min="";   try { var_min=(element.fiftyTwoWeekLowChangePercent*100).toFixed(2) } catch(Exxx) {}
  var var_max="";   try { var_max=(element.fiftyTwoWeekHighChangePercent*100).toFixed(2) } catch(Exxx) {}


  var bookValue="";   try { bookValue=element.bookValue } catch(Exxx) {}
  var avgAnalystRating="";   try { avgAnalystRating=element.averageAnalystRating; } catch(Exxx) {}


  /*
  var INCREMENTO_VOL=false; 
  var VAR_52_MIN=false;
  var VAR_52_MAX=false; 

  var PER=false; 
  var EPS=false;
  var DIVI_RENTAB=false; 
  var PRICE_TO_BOOK=false; 
  var BOOK_VALUE=false; 
  var RESULTADOS=false;
  var RATING_ANALISTOS=false; 
  */

  var element_BANNED=false;

  //Los indices no entran en el filtro
  if(element.quoteType!="INDEX") {
    
  for (let i=0;i<filterList.length;i++) 
  {
    //console.log(""+element[filterList[i].value]+" :"+filterList[i].minCursor);
    //console.log(""+element[filterList[i].value]+" :"+filterList[i].maxCursor);

    var compareValue=element[filterList[i].value];
    compareValue=Math.round((compareValue*filterList[i].multi));
    

   if(filterList[i].minCursor!=filterList[i].maxCursor
    &&((compareValue<filterList[i].minCursor)
     || (compareValue>filterList[i].maxCursor))) 
   {
    element_BANNED=true;
    //console.log("element BANNED!!!")
    //console.log("CompareValue:"+compareValue);
    //console.log("filterList[i].minCursor:"+filterList[i].minCursor)
    //console.log("filterList[i].maxCursor:"+filterList[i].maxCursor)
    //break;
   }
  }
  }

  if(element_BANNED) return(null);

  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return (
       
    
    <Flex justifyContent="space-between" borderBottom="1px" 
          borderColor="lightgray"
          ml={-2} mr={-2}
          textAlign="center" alignItems="center" minHeight="3.8pc" onClick={() => showElement(element)}  _hover={{ cursor: "pointer" }}>

<VStack  w={"7em"} textAlign="left">
<Text fontSize="sm"  w={"7em"} textAlign="initial">{title}</Text>
        <Text fontSize="sm" w={"7em"} fontWeight="bold" >{symbol}</Text> 
        
        </VStack>
    
      
      <VStack  w={"6em"} >
      <Box
        borderRadius="lg"
        fontSize="13"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="1px"
        w={"4pc"}
        h={"1.5pc"}
        bg={color1}
        textColor={color2}
      >
        {simbol}{percent}%
      </Box>
      <Text
          fontSize="12"
          textColor={loss ? "red" : "green"}
          fontWeight="normal"
          align="right"
          
        >
          {simbol}{change}
        </Text>
     
      </VStack>


      <Box ml={0} mr={0} w={"5em"} >
      <Heading fontSize="sm" textAlign="center" w="6pc">{coti}{moneda}</Heading>
      </Box>

   
      {(DISTANCIA_M50)&&<Text fontSize="sm"  color="black"  minW="4em" >{dis_m50}%</Text>}
      {(DISTANCIA_M200)&&<Text fontSize="sm"  color="black"  minW="4em" >{dis_m200}%</Text>}
     
      {(INCREMENTO_VOL)&&<Text fontSize="sm"  color="black"  minW="4em"  >{inc_vol}%</Text>}
      {(VAR_52_MIN)&&<Text fontSize="sm"  color="black" minW="4em" maxW="4em"  >{var_min}%</Text>}
      {(VAR_52_MAX)&&<Text fontSize="sm"  color="black"   minW="4em" maxW="4em" >{var_max}%</Text>}


     
      {(NO_CRITERI|| PER)&&<Text fontSize="sm"  color="black" minW="4em" maxW="4em">{trailingPE}</Text>}
      {(NO_CRITERI|| EPS)&&<Text fontSize="sm"  color="black" minW="4em" maxW="4em" >{epsTrailingTwelveMonths}{moneda}</Text>}
       {(NO_CRITERI || PRICE_TO_BOOK)&&<Text  minW="4em" maxW="4em" fontSize="sm"  color="black" width={"3pc"} >{priceToBook}</Text>}
       {(NO_CRITERI || DIVI_RENTAB)&&<Text  minW="5em" maxW="5em" fontSize="sm"  color="black" width={"6pc"} >{trailingAnnualDividendRate}{moneda} ({trailingAnnualDividendYield}%)</Text>}

      {BOOK_VALUE&&<Text fontSize="sm"  color="black" minW="4em" maxW="4em">{bookValue}</Text>}
      {RATING_ANALISTOS&&<Text fontSize="sm"  color="black" minW="5em" maxW="5em" >{avgAnalystRating}</Text>}
   
  

  
      {!MODE_COMPACT&&<Text fontSize="sm"  color="black" minW="6em" maxW="6em" >{regularMarketVolume}</Text>}
       {!MODE_COMPACT&&<Text fontSize="sm"  color="black" minW="7em" maxW="7em" >{marketCAP}</Text>}

      {1==2&&<Text fontSize="sm"  color="black" minW="4em" maxW="4em" >{element.bookValue}</Text>}


    </Flex>
    
 
    );
  }
  else {
    return (
       
    
      <Flex justifyContent="space-between" borderBottom="1px" 
      pl={1} pr={1}
      ml={-2} mr={-2}
            borderColor="lightgray"
            textAlign="center" alignItems="center" minHeight="3.8pc" onClick={() => showElement(element)}  _hover={{ cursor: "pointer" }}>
  
          {!SUPER_COMPACT&&<Text fontSize="sm"  fontWeight="bold" width={"3.8pc"}  textAlign="initial">{symbol}</Text>}
          {!SUPER_COMPACT&&<Text fontSize="sm"  width={"12pc"} textAlign="initial">{title}</Text>}

          {SUPER_COMPACT&&
           <VStack textAlign="initial">
           <Text fontSize="sm"  width={"12pc"} textAlign="initial">{title}</Text>
           <Text fontSize="sm"  fontWeight="bold" width={"12pc"}  textAlign="initial">{symbol}</Text>
           </VStack>
          }
      
        
        {!SUPER_COMPACT&&<HStack  w={"7pc"}>
        <Text
            fontSize="12"
            textColor={loss ? "red" : "green"}
            fontWeight="normal"
            align="right"
            w={"2.5pc"}
          >
            {simbol}{change}
          </Text>
        <Box
          borderRadius="lg"
          fontSize="13"
          fontWeight="semibold"
          textAlign="center"
          paddingTop="1px"
          w={"4pc"}
          h={"1.5pc"}
          bg={color1}
          textColor={color2}
        >
          {simbol}{percent}%
        </Box>
        </HStack>}

        {SUPER_COMPACT&&<VStack  w={"7pc"}>
        <Box
          borderRadius="lg"
          fontSize="13"
          fontWeight="semibold"
          textAlign="center"
          paddingTop="1px"
          w={"4pc"}
          h={"1.5pc"}
          bg={color1}
          textColor={color2}
        >
          {simbol}{percent}%
        </Box>
        <Text
            fontSize="12"
            textColor={loss ? "red" : "green"}
            fontWeight="normal"
            align="right"
            w={"3.5pc"}
          >
            {simbol}{change}
          </Text>
        
        </VStack>}
  
  
        <Box ml={0} mr={0}  >
        <Heading fontSize="sm" textAlign="center" w="6pc">{coti}{moneda}</Heading>
        </Box>
  
     
        {(DISTANCIA_M50)&&<Text fontSize="sm"  color="black" width={"3.2pc"} >{dis_m50}%</Text>}
        {(DISTANCIA_M200)&&<Text fontSize="sm"  color="black" width={"3.2pc"} >{dis_m200}%</Text>}
       
        {(INCREMENTO_VOL)&&<Text fontSize="sm"  color="black" width={"3.2pc"} >{inc_vol}%</Text>}
        {(VAR_52_MIN)&&<Text fontSize="sm"  color="black" width={"3.2pc"} >{var_min}%</Text>}
        {(VAR_52_MAX)&&<Text fontSize="sm"  color="black" width={"3.2pc"} >{var_max}%</Text>}
  
  
       
        {(NO_CRITERI|| PER)&&<Text fontSize="sm"  color="black" width={"3pc"} >{trailingPE}</Text>}
        {(NO_CRITERI|| EPS)&&<Text fontSize="sm"  color="black" width={"3pc"} >{epsTrailingTwelveMonths}{moneda}</Text>}
         {(NO_CRITERI || PRICE_TO_BOOK)&&<Text fontSize="sm"  color="black" width={"3pc"} >{priceToBook}</Text>}
         {(NO_CRITERI || DIVI_RENTAB)&&<Text fontSize="sm"  color="black" width={"6pc"} >{trailingAnnualDividendRate}{moneda}  ({trailingAnnualDividendYield}%)</Text>}
  
        {BOOK_VALUE&&<Text fontSize="sm"  color="black" width={"3pc"} >{bookValue}</Text>}
        {RATING_ANALISTOS&&<Text fontSize="sm"  color="black" width={"5pc"} >{avgAnalystRating}</Text>}
     
    
  
    
        {!MODE_COMPACT&&<Text fontSize="sm"  color="black" width={"6pc"} >{regularMarketVolume}</Text>}
        {!MODE_COMPACT&&<Text fontSize="sm"  color="black" width={"8pc"} >{marketCAP}</Text>}
  
        {1==2&&<Text fontSize="sm"  color="black" width={"4pc"} >{element.bookValue}</Text>}
  
  
      </Flex>
      
   
      );
  }
  };
  