import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef, useEffect } from "react";
import { useFetchTodoCotis } from "../utils/useFetchTodoCotis";

import ElementScreener from "./ElementScreener";
import glovar from './glovar';
import { getMONEDADivisa } from './glovar'
import { Image } from "@chakra-ui/image";
import { Container,Text,Box,HStack,Flex, GridItem } from "@chakra-ui/layout";

import { SimpleGrid } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";


import  bdtic from './bdtic';

import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react'

import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip
} from '@chakra-ui/react'

import { Badge, chakra,  useRangeSlider } from "@chakra-ui/react";
import Thumb from './Thumb.tsx';


import i18n from "../../i18n";




export default ({arrayTickers, anotherSearch, criteri,noshowFilters, modeCompact, superCompact, arrayCotis}) => {

  var NO_SHOW_FILTERS=false;
  var MODE_COMPACT=false;
  var SUPER_COMPACT=false;

  var COL_SPAN_ASSETS=13;

  if(noshowFilters==true) {
    NO_SHOW_FILTERS=true;
    COL_SPAN_ASSETS=16;
  }

  if(modeCompact==true) {
    MODE_COMPACT=modeCompact;
  }

  if(superCompact==true) {
    SUPER_COMPACT=superCompact;
  }

  var NO_CRITERI=true;

  var DISTANCIA_M50=false; 
  var DISTANCIA_M200=false; 
  var INCREMENTO_VOL=false; 
  var VAR_52_MIN=false;
  var VAR_52_MAX=false; 

  var PER=false; 
  var EPS=false;
  var DIVI_RENTAB=false; 
  var PRICE_TO_BOOK=false; 
  var BOOK_VALUE=false; 
  var RESULTADOS=false;
  var RATING_ANALISTOS=false; 

  var LIST_TICKERS=[];
  var MY_LAST_SEARCH=[];
  var numColumns=20; //max

  //var MY_FILTERS_LIST=[];
  var filter = {
    name: "PER RATIO",
    descript: "Indicates the number of times the net profit of a company is included in the price of one of its shares.",
    //descript: "Indica el número de veces que el beneficio neto de una empresa está incluido en el precio de una de sus acciones.",
    min:-30,
    max:400,
  }

  //const [refreshAsset, setRefreshAsset] = useState(false);

  const [doRefresh, setDoRefresh] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [doOrder, setDoOrder] = useState(false);

  const [sortbyName,setsortByName] = useState(false);
  const [arraySortby,setArraySortby] = useState([]);


  useEffect(() => {
    setRefresh(false);
    setDoRefresh(false);
  },[]);

  
  for(let ii=0; ii<numColumns; ii++) {  arraySortby.push(false); }


  function SliderThumbWithTooltip({nameValue,descriptValue,minValue,maxValue, uniValue,minCursorValue, maxCursorValue, valueValue}) {
    //const [sliderValue, setSliderValue] = useState(5)
    //const [showTooltip, setShowTooltip] = useState(false)
    const [sliderValue, setSliderValue] = useState(minValue,maxValue)
  
  
    var unidad="";
    if(uniValue!=null) {unidad=uniValue;}
    //this.setSliderValue(minValue,maxValue);
  
    return (
      <Box marginBottom={10}>
        <Text fontSize="md" fontWeight="semibold" mb={2} textAlign="initial" textColor="black">{nameValue}</Text>
            <Text fontSize="sm" mb={2}>{descriptValue}</Text>
  
        <Flex flexDir='row' justifyContent='space-between'>
    <Text fontSize="md" fontWeight="semibold">{sliderValue[0] || minCursorValue}{unidad}</Text>
    <Text fontSize="md"fontWeight="semibold">{sliderValue[1] || maxCursorValue}{unidad}</Text>
  </Flex>
      <RangeSlider
      min={minValue}
      max={maxValue}
      colorScheme='gray'
      defaultValue={[minCursorValue,maxCursorValue]}
      aria-label={['min', 'max']}
      onChangeEnd={(val) => { 
        
        setSliderValue(val); 

       
        for (let i=0;i<glovar.MY_FILTERS_LIST.length;i++) 
        {
         if(glovar.MY_FILTERS_LIST[i].value==valueValue)  {
          glovar.MY_FILTERS_LIST[i].minCursor=JSON.parse(JSON.stringify(val[0]));
          glovar.MY_FILTERS_LIST[i].maxCursor=JSON.parse(JSON.stringify(val[1]));
         }
        }
        glovar.REFRESH_FILTRES=false;
        setDoOrder(!doOrder); 
        //console.log(val); 
        
      //console.log("minCursorValue:"+minCursorValue) 
    } }
    >
      <RangeSliderTrack>
        <RangeSliderFilledTrack />
      </RangeSliderTrack>
      <RangeSliderThumb index={0} />
      <RangeSliderThumb index={1} />
    </RangeSlider>
  </Box>
    )
  }

  
  /*function doOrderByOLD(pos,field) 
  {
    anotherSearch=false;
 
    if(field=="shortName") 
    {
      if(!arraySortby[pos]) { 
       try { glovar.LAST_SEARCH.sort((a, b) => a[field].localeCompare(b[field])); }  catch(Exxpp) {}
     }
     else {
      try {  glovar.LAST_SEARCH.sort((b, a) => a[field].localeCompare(b[field])); }  catch(Exxpp) {}
     }
     arraySortby[pos] = !arraySortby[pos];
     setArraySortby(arraySortby) 
    }

    else {
      if(!arraySortby[pos]) { 
      try { glovar.LAST_SEARCH.sort(function(a,b){ return parseFloat(b[field])  - parseFloat(a[field]);}) } catch(Exxpp) {}
    }
    else {
      try { glovar.LAST_SEARCH.sort(function(b,a){ return parseFloat(b[field])  - parseFloat(a[field]);}) } catch(Exxpp) {}
    }
    arraySortby[pos] = !arraySortby[pos];
    setArraySortby(arraySortby) 
    }
    //setRefresh(true);
  }*/

  function doOrderBy(pos, field) {
    anotherSearch = false;
  
    if (field == "shortName") {
      try {
        glovar.LAST_SEARCH.sort((a, b) =>
          a[field].localeCompare(b[field])
        );
      } catch (Exxpp) {}
    } else {
      try {
        glovar.LAST_SEARCH.sort(function (a, b) {
          const valA = a[field] != null && a[field] != 0 ? parseFloat(a[field]) : 0;//Infinity;
          const valB = b[field] != null && b[field] != 0 ? parseFloat(b[field]) : 0;//Infinity;
          return valB - valA;
        });
      } catch (Exxpp) {}
    }
  
    arraySortby[pos] = !arraySortby[pos];
    setArraySortby(arraySortby);
  }
  

  //console.log("criteri:"+JSON.stringify(criteri))
  
  NO_CRITERI=true; 
  try { 
    if(criteri[0].label.length>2) 
    { 
     NO_CRITERI=false; 

     for (let i=0;i<criteri.length;i++) 
     {
      switch(criteri[i].value)
      {
       case "fiftyDayAverageChangePercent": DISTANCIA_M50=true; break;
       case "twoHundredDayAverageChangePercent": DISTANCIA_M200=true; break;
       case "averageDailyVolume10Day,averageDailyVolume3Month":  INCREMENTO_VOL=true; break;
       case "fiftyTwoWeekLowChangePercent": VAR_52_MIN=true; break;
       case "fiftyTwoWeekHighChangePercent":VAR_52_MAX=true; break;

       case "trailingPE": PER=true; break;
       case "epsTrailingTwelveMonths": EPS=true; break;
       case "trailingAnnualDividendRate,trailingAnnualDividendYield": DIVI_RENTAB=true; break;
       case "priceToBook": PRICE_TO_BOOK=true; break;
       case "bookValue": BOOK_VALUE=true; break;
       case "earningsTimestamp,earningsTimestampStart,earningsTimestampEnd": RESULTADOS=true; break;
       case "averageAnalystRating": RATING_ANALISTOS=true; break;     
     }
    }
  
   }
  } catch(Exx) {}


 if(arrayTickers==null) {return(null)}

  //console.log(JSON.stringify(arrayTickers))
  //console.log("abans for")
  //console.log("LIST_TICKERS:"+LIST_TICKERS)
  for (let i=0;i<arrayTickers.length;i++) {

    var INDEX_REF="";

    switch(arrayTickers[i].label)
    {
      case "IBEX 35": INDEX_REF="IBEX35_STOCKS"; break;
      case "MERCADO CONTINUO": INDEX_REF="MADRID_STOCKS"; break;
      case "DOW JONES": INDEX_REF="DOW_STOCKS"; break;
      case "NASDAQ 100": INDEX_REF="NASDAQ100_STOCKS"; break;
      case "S&P 500": INDEX_REF= "SP500_STOCKS"; break;

      case "CAC 40": INDEX_REF= "CAC40_STOCKS"; break;
      case "SBF 120": INDEX_REF= "SBF120_STOCKS"; break;
      case "CAC ALLSHARE": INDEX_REF= "CAC_ALL_SHARE_STOCKS"; break;

     
      case "DAX 40": INDEX_REF= "DAX30_STOCKS"; break;
      case "TECDAX": INDEX_REF= "TECDAX_STOCKS"; break;
      case "MDAX": INDEX_REF= "MDAX_STOCKS"; break;
      case "SDAX": INDEX_REF= "SDAX_STOCKS"; break;
      case "DAX ALL SHARE": INDEX_REF= "DAX_ALL_SHARE_STOCKS"; break;
      case "TECDAX SHARE": INDEX_REF= "TECDAX_SHARE_STOCKS"; break;

      case "EUROSTOXX 50": INDEX_REF= "EURO50_STOCKS"; break;
      case "FTSE 100": INDEX_REF= "FTSE100_STOCKS"; break;

     
      /*
      SBF120_STOCKS
      MIB30_STOCKS
      TECDAX_SHARE_STOCKS
      TECDAX_STOCKS
      EURO50_STOCKS
      */
      
      default: INDEX_REF="DOW_STOCKS"; break;
           
    }

    
    for(let i=6;i<bdtic[INDEX_REF].length;i+=2) {
      //console.log("i:"+i+ " list:"+LIST_TICKERS)
      try {
        //if(i==6) { LIST_TICKERS=LIST_TICKERS+bdtic[INDEX_REF][i]; }
        //else 
        { LIST_TICKERS=LIST_TICKERS+bdtic[INDEX_REF][i]+",";  }
      }
      catch(Exxx) {}
    }

  }

  //console.log("deso for")
  //console.log("LIST_TICKERS:"+LIST_TICKERS)
  let ARRAY_COTIS="";
  try {
  if(arrayCotis.length>0) {
    ARRAY_COTIS=arrayCotis;
  }
  else {
  ARRAY_COTIS=glovar.ARRAY_COTIS_MORE_POPULAR;
  }
}
catch (Exx) {
  ARRAY_COTIS=glovar.ARRAY_COTIS_MORE_POPULAR;
}

  try {
    if(LIST_TICKERS.length>0) { ARRAY_COTIS=LIST_TICKERS; }
  } catch(Exx) {}
 

  
  //console.log("ARRAY_COTIS1:"+LIST_TICKERS);


  if(anotherSearch) 
  {
  const { todo, isFetching } = useFetchTodoCotis(ARRAY_COTIS,0)
 
    if (!isFetching) 
    {
      glovar.LAST_SEARCH=todo;
      MY_LAST_SEARCH=todo;

      if(!doRefresh) 
      {
       setRefresh(true);
       setDoRefresh(true);
      }
    }
  }
  else {
    MY_LAST_SEARCH=glovar.LAST_SEARCH;
  }


 if(glovar.REFRESH_FILTRES) 
 {

  try { 
    if(criteri[0].label.length>2) 
    { 
  for (let i=0;i<criteri.length;i++) 
  {
    //console.log(i+" criteri.length:"+criteri.length)
   //switch(criteri[i].value)

   //De momento no hay filtro por incremento de volumen pq son dos ratios separados que dividimos entre si (y no se guardan, solo se muestran en elementScreener)
   //Más adelante ya se le dará soporte
   if(criteri[i].value!="averageDailyVolume10Day,averageDailyVolume3Month")
   {

    
    //case "fiftyDayAverageChangePercent":   
       var filter = {
      name:   criteri[i].name, 
      unidad: criteri[i].unidad,
      descript: criteri[i].descript, 
      value:criteri[i].value,
      multi:criteri[i].multi,
      min:0, max: 0, }; 
    
      //for (i in scores) {   console.log(scores[i]); }
      let min=0;
      let max=0;
      MY_LAST_SEARCH.forEach((element,index) => {
        //console.log("index:"+index)
        //console.log("criteri[i].value:"+criteri[i].value);
        try {
        if(index==1)
        {
          min=element[criteri[i].value];
          max=element[criteri[i].value];
          //console.log("min:"+min);
          }

       if(min>element[criteri[i].value]) { min=element[criteri[i].value];}
       if(max<element[criteri[i].value]) { max=element[criteri[i].value];}
        } catch(Exxx) {}
      });

      //console.log("3 min:"+min);
      //console.log("3 max:"+max);

      filter.min=Math.round((min*filter.multi)); //.toFixed();
      filter.max=Math.round((max*filter.multi));
      
      filter.minCursor=JSON.parse(JSON.stringify(filter.min));
      filter.maxCursor=JSON.parse(JSON.stringify(filter.max));
      
      //console.log("4");

      var INCLUIR=true;

      for (let z in glovar.MY_FILTERS_LIST) {   
        if(glovar.MY_FILTERS_LIST[z].value==filter.value) {
          INCLUIR=false;
        } 
      }

      if(filter.minCursor!=filter.maxCursor && INCLUIR) 
      {
        glovar.MY_FILTERS_LIST.push(filter); 
        //console.log("tamaño MY_FILTER:"+glovar.MY_FILTERS_LIST.length)
      }
      
      //console.log("5");
      /*if(!glovar.MY_FILTERS_LIST.includes(filter)) 
      {
      glovar.MY_FILTERS_LIST.push(filter); 
      }*/
      
      
      //break;
    
     
  }
  }
  }
  }
  catch(Exxx) {}
 

  try {
  if(glovar.MY_FILTERS_LIST.length<1) 
  {
   
    //console.log("criterix:"+criteri)
    if(criteri==null || criteri.length<1) {

      /*if(MY_LAST_SEARCH[0].quoteType=="INDEX") {
        MY_LAST_SEARCH[0].regularMarketVolume=" ";
      }*/

      for (let i=0;i<glovar.default_criteria.length;i++) 
      {
        //console.log("Default criteria: "+glovar.default_criteria[i].value);
    //case "fiftyDayAverageChangePercent":   
    var filter = {
      name:  glovar.default_criteria[i].name, 
      unidad: glovar.default_criteria[i].unidad,
      descript: glovar.default_criteria[i].descript, 
      value: glovar.default_criteria[i].value,
      multi: glovar.default_criteria[i].multi,
      min:0, max: 0, minCursor:0, maxCursor:0 }; 
    
      //for (i in scores) {   console.log(scores[i]); }
      let min=0;
      let max=0;
      
      MY_LAST_SEARCH.forEach((element,index) => {
        if(index==1)
        {
          min=element[glovar.default_criteria[i].value];
          max=element[glovar.default_criteria[i].value];
          //console.log("min:"+min);
          }
          
           //console.log(min+" vs "+element[glovar.default_criteria[i].value]) 
         if(min>element[glovar.default_criteria[i].value]) { min=element[glovar.default_criteria[i].value];}
         if(max<element[glovar.default_criteria[i].value]) { max=element[glovar.default_criteria[i].value];}
        
      });

      filter.min=Math.round((min*filter.multi)); //.toFixed();
      filter.max=Math.round((max*filter.multi));
      
      filter.minCursor=JSON.parse(JSON.stringify(filter.min));
      filter.maxCursor=JSON.parse(JSON.stringify(filter.max));
      

      var INCLUIR=true;

      for (let j in glovar.MY_FILTERS_LIST) {   
        if(glovar.MY_FILTERS_LIST[j].value==filter.value) {
          INCLUIR=false;
        } 
      }

      if(filter.minCursor!=filter.maxCursor && INCLUIR) 
      {
        glovar.MY_FILTERS_LIST.push(filter); 
        //console.log("tamaño MY_FILTER:"+glovar.MY_FILTERS_LIST.length)
      }
    }




}
  }
  }  
  catch(Exxx) { /*console.log("petada:"+Exxx)*/ }

}
    
if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
{
  return(
    <SimpleGrid   columns={16} gap={2}  height="maxHeight" backgroundColor="#F8F8F8">
  
    <GridItem colSpan={16} p={3}  rounded={5} background="#FFFFFF">
      
      <Box height="maxHeight" background="#FFFFFF">  
          <Flex justifyContent="space-between" alignItems="center" textAlign="center" h="2pc" mt={-2} mb={2} ml={-1} mr={-1} textColor="black" fontSize="sm" background="#EEEEEE">
             
          
             <HStack minW="7em" maxW="7em"  _hover={{ cursor:"pointer"}}  onClick= {() => { /*setRefresh(false);*/ doOrderBy(1,"shortName"); setDoOrder(!doOrder) }}>
              <Text    fontSize="sm" textAlign="initial"  mr={-1}> Name</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>
              
                
                <HStack  minW="6em"  ml={1} _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketChangePercent"); setDoOrder(!doOrder) }}>
                <Text  textAlign="right" mr={0}>Chg(%)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>
          
          
                <HStack  minW="6em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketPrice"); setDoOrder(!doOrder) }}>
                  <Text  mr={-1}>Price</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>
          
              
                
                  {(DISTANCIA_M50)&&<HStack  minW="3em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyDayAverageChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>av50</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(DISTANCIA_M200)&&<HStack  minW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"twoHundredDayAverageChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>av200</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

                  {(INCREMENTO_VOL)&&<HStack minW="3em" justifyContent="center"> 
                  <Text mr={0}>vol inc</Text></HStack>}
                  {(VAR_52_MIN)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyTwoWeekLowChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>52mn (%)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(VAR_52_MAX)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyTwoWeekHighChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>52mx (%)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

            

                {(NO_CRITERI || PER)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"trailingPE"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>PE Rat</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(NO_CRITERI || EPS)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"epsTrailingTwelveMonths"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>EPS</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                {(NO_CRITERI || PRICE_TO_BOOK)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"priceToBook"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Pr/bk</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(NO_CRITERI || DIVI_RENTAB)&&<HStack minW="5em" maxW="5em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"trailingAnnualDividendYield"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Divdend (y)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

                    
             
                  {(BOOK_VALUE)&&<HStack minW="4em" maxW="4em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"bookValue"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Book vl</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(RATING_ANALISTOS)&&<HStack minW="5em" maxW="5em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"averageAnalystRating"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Rt Anlyst</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  
      


                
                  {!MODE_COMPACT&&<HStack minW="6em" maxW="6em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketVolume"); setDoOrder(!doOrder) }}>
                  <Text  mr={-1}>Volume</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {!MODE_COMPACT&&<HStack minW="7em" maxW="7em" justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"marketCap"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Market Cap</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}  
          
          
              </Flex>
      
    

        {refresh&&MY_LAST_SEARCH!=null&&MY_LAST_SEARCH.map((todo) => 
          <ElementScreener element={todo} name={todo.shortName} price={todo.regularMarketPrice}
                   diff={todo.regularMarketChangePercent} 
                   pordiff={todo.regularMarketChange}
                   elementOrder={doOrder}
                   elementCriteri={criteri}
                   filterList={glovar.MY_FILTERS_LIST}
                   modeCompact={MODE_COMPACT}
                   superCompact={SUPER_COMPACT}
                   moneda={"$"}></ElementScreener>
        )} 
        </Box>  

      </GridItem>

    
      </SimpleGrid>
  );
  }
  else {
  return (
    <SimpleGrid   columns={16} gap={2}  height="maxHeight" backgroundColor="#F8F8F8">
  
    {<GridItem colSpan={COL_SPAN_ASSETS} p={4}  rounded={10} background="#FFFFFF">
      
      <Box height="maxHeight" background="#FFFFFF">  
          <Flex justifyContent="space-between" alignItems="center" textAlign="center" h="2pc" mt={-2} mb={2}
          pl={1} pr={1}
           ml={-2} mr={-2} textColor="black" fontSize="sm" background="#EEEEEE">
             
          {!SUPER_COMPACT&&<Text fontSize="sm"  width={"3.8pc"} textAlign="initial" pl="0.3pc">Ticker</Text>}
             <HStack width={"12pc"} _hover={{ cursor:"pointer"}}  onClick= {() => { /*setRefresh(false);*/ doOrderBy(1,"shortName"); setDoOrder(!doOrder) }}>
              <Text fontSize="sm" textAlign="initial"  mr={-1}>Name</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>
              
                
                <HStack  w={"7pc"} _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketChangePercent"); setDoOrder(!doOrder) }}>
                {!SUPER_COMPACT&&<Text  w={"3.5pc"} align="right" textAlign="right">Chg</Text>}
                {!SUPER_COMPACT&&<HStack width={"4.5pc"}><Text textAlign="right" mr={-1}>Chg(%)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                {SUPER_COMPACT&&<HStack ml={"2pc"}  width={"4.5pc"}><Text mr={-1}>Chg(%)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

  
  
                </HStack>
          
          
                <HStack width={"6pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketPrice"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Price</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>
          
              
                
                  {(DISTANCIA_M50)&&<HStack width={"4pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyDayAverageChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>a50 %</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(DISTANCIA_M200)&&<HStack width={"4pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"twoHundredDayAverageChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>a200 %</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

                  {(INCREMENTO_VOL)&&<HStack width={"3pc"} justifyContent="center"> 
                  <Text mr={0}>vol inc</Text></HStack>}
                  {(VAR_52_MIN)&&<HStack width={"3pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyTwoWeekLowChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>52mn Chg</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(VAR_52_MAX)&&<HStack width={"3pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"fiftyTwoWeekHighChangePercent"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>52mx Chg</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

            

                {(NO_CRITERI || PER)&&<HStack width={"4pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"trailingPE"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>PE Rat</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(NO_CRITERI || EPS)&&<HStack width={"3pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"epsTrailingTwelveMonths"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>EPS</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                {(NO_CRITERI || PRICE_TO_BOOK)&&<HStack width={"3pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"priceToBook"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Pr/bk</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(NO_CRITERI || DIVI_RENTAB)&&<HStack width={"6pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"trailingAnnualDividendYield"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Dividend (y)</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}

                    
             
                  {(BOOK_VALUE)&&<HStack width={"4pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"bookValue"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Book vl</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  {(RATING_ANALISTOS)&&<HStack width={"5pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"averageAnalystRating"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Rtg Anlyst</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                  
      


                
                  {!MODE_COMPACT&&<HStack width={"6pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"regularMarketVolume"); setDoOrder(!doOrder) }}>
                  <Text  mr={-1}>Volume</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
                {!MODE_COMPACT&&<HStack width={"8pc"} justifyContent="center" _hover={{ cursor: "pointer" }} onClick= {() => { doOrderBy(3,"marketCap"); setDoOrder(!doOrder) }}>
                  <Text mr={-1}>Market Cap</Text><Image pt={0.5}  src='assets/arrows.png' h="12px"/></HStack>}
          
          
              </Flex>
      
    

        {refresh&&MY_LAST_SEARCH!=null&&MY_LAST_SEARCH.map((todo) => 
          <ElementScreener element={todo} name={todo.shortName} price={todo.regularMarketPrice}
                   diff={todo.regularMarketChangePercent} 
                   pordiff={todo.regularMarketChange}
                   elementOrder={doOrder}
                   elementCriteri={criteri}
                   filterList={glovar.MY_FILTERS_LIST}
                   modeCompact={MODE_COMPACT}
                   superCompact={SUPER_COMPACT}
                   moneda={"$"}></ElementScreener>
        )} 
        </Box>  

      </GridItem>}

      {!NO_SHOW_FILTERS&&<GridItem colSpan={3} p={5}  rounded={10} background="#FFFFFF">
      
      <Box height="maxHeight" background="#FFFFFF" pl={0} pr={0}>  

     
             
          <Text fontSize="lg" fontWeight="bold"  mb={6} textAlign="center" textColor="black">{i18n.t("filtros")}</Text>

         
          {glovar.MY_FILTERS_LIST.map((filter) => 
          <SliderThumbWithTooltip nameValue={filter.name} descriptValue={filter.descript} minValue={filter.min} maxValue={filter.max} uniValue={filter.unidad}
          minCursorValue={filter.minCursor} maxCursorValue={filter.maxCursor}
          valueValue={filter.value}
           />
        )} 

          

         
          
      </Box>
      </GridItem>}
      </SimpleGrid>
  );
  }
      
    

    //tornem l'ultima cerca
   

    //const  todoTopTraders = useFetchTodoToptraders()
}
