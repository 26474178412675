
import { tsvParse, csvParse, csvParseRows } from  "d3-dsv";
import { timeParse } from "d3-time-format";
import { csv,dsv,  text } from "d3-fetch"; 
import Papa from "papaparse"
import glovar from '../components/content/glovar';


import { useEffect, useRef } from "react";



export function AdComponent320() {
  const banner = useRef();

  const atOptions = {
    //key: "aeb5262a7b5a337ae81789065fd4b56a",
    'key' : glovar.KEY_ADCOMP_320_OK,
    format: "iframe",
    height: 50,
    width: 320,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export function AdComponent600() {
  const banner = useRef();

  const atOptions = {
    key: glovar.KEY_ADCOMP_600,
    format: "iframe",
    height: 600,
    width: 160,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}


export function AdComponent468() {
  const banner = useRef();

  //console.log("glovar.KEY_ADCOMP_468_OK:"+glovar.KEY_ADCOMP_468_OK)
  const atOptions = {
    //key: "f29a724eac91492e19bb63a483d5527b",
    key: glovar.KEY_ADCOMP_468_OK, //
    format: "iframe",
    height: 60,
    width: 468,
  };

  //console.log("atOptions.key:"+atOptions.key)

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export function AdComponent728() {
  const banner = useRef();

  const atOptions = {
    key: glovar.KEY_ADCOMP_728, //"0359f5870e85e20b331cf32880735bbe",
    format: "iframe",
    height: 90,
    width: 728,
    //params : {}
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export const setElementData = (element,ticker) => {
  sessionStorage.setItem('elementdata_'+ticker, JSON.stringify(element));
}

export const getElementData = (ticker) => {
  const userStr = sessionStorage.getItem('elementdata_'+ticker);
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
 
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// return the token from the session storage
export const getEmail = () => {
  return sessionStorage.getItem('email') || null;
}
 
export const getModeUser = () => {
  return sessionStorage.getItem('modeuser') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('modeuser');
  sessionStorage.removeItem('portfolioed');
  sessionStorage.removeItem('favoritos');
  sessionStorage.removeItem('portfolio');
  sessionStorage.removeItem('historial');
}
 
// set the token and user from the session storage
export const setUserSession = (token, user, email,modeuser) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('email', JSON.stringify(email));
  sessionStorage.setItem('modeuser', JSON.stringify(modeuser));
}

export const setModeUser = (modeuser) => {
  sessionStorage.setItem('modeuser', JSON.stringify(modeuser));
}

export const setCurrent_porfolio_ED = (portfolio_ED) => {

  var portfolio_ED_PARSED=JSON.stringify(portfolio_ED);
  //console.log("guardo portfolioED:"+portfolio_ED_PARSED)
  sessionStorage.setItem('portfolioed', portfolio_ED_PARSED);
  //console.log("ed guardadoooooooooo!")
}

export const setTOPTRADERSLIST = (toptradersList) => {

  var toptradersList_PARSED=JSON.stringify(toptradersList);
  sessionStorage.setItem('toptradersList', toptradersList_PARSED);
  sessionStorage.setItem("lasttime_toptraderlist",Date.now())
}

export const getTOPTRADERSLIST = () => {
  return sessionStorage.getItem('toptradersList') || null;
                               
}

export const getLastTimeTOPTRADERSLIST = () => {
  return sessionStorage.getItem('lasttime_toptraderlist') || null;
                               
}

export const setMOREPOPULAR = (morepopularList) => {
  var morepopularList_PARSED=JSON.stringify(morepopularList);
  sessionStorage.setItem('morepopularList', morepopularList_PARSED);
  sessionStorage.setItem("lasttime_morepopularlist",Date.now())
  //console.log("guardoo setmorepopulaaaaaaaaaaaar")
}

export const getMOREPOPULAR = () => { 
  return sessionStorage.getItem('morepopularList') || null;                            
}

export const getLastTimeMOREPOPULAR = () => {
  return sessionStorage.getItem('lasttime_morepopularlist') || null;
}

export const setMYWATCHLIST = (mywatchlistList) => {
  var mywatchlistList_PARSED=JSON.stringify(mywatchlistList);
  sessionStorage.setItem('mywatchlistList', mywatchlistList_PARSED);
  sessionStorage.setItem("lasttime_mywatchlistList",Date.now())
}
export const getMYWATCHLIST = () => { 
  return sessionStorage.getItem('mywatchlistList') || null;                            
}
export const getLastTimeMYWATCHLIST = () => {
  return sessionStorage.getItem('lasttime_mywatchlistList') || null;
}


export const setMYPORTFOLIO = (myportfolioList) => {
  var myportfolioList_PARSED=JSON.stringify(myportfolioList);
  sessionStorage.setItem('myportfolioList', myportfolioList_PARSED);
  sessionStorage.setItem("lasttime_myportfolioList",Date.now())
}
export const getMYPORTFOLIO = () => { 
  return sessionStorage.getItem('myportfolioList') || null;                            
}

export const getLastTimeMYPORTFOLIO = () => {
  return sessionStorage.getItem('lasttime_myportfolioList') || null;
}

export const getporfolio_ED = () => {
  
  return sessionStorage.getItem('portfolioed') || null;
                               
}

export const setUserSessionStocks = (user,favoritos, historial, portfolio) => {
  //glovar.CURRENT_FAVORITOS, glovar.CURRENT_HISTORIAL,glovar.CURRENT_PORTFOLIO

  var favoritosPARSED=JSON.stringify(favoritos);
      //favoritosPARSED = favoritosPARSED.replace('[', '');
      //favoritosPARSED = favoritosPARSED.replace(']', '');

  var portfolioPARSED=JSON.stringify(portfolio);
      //portfolioPARSED = portfolioPARSED.replace('[', '');
      //portfolioPARSED = portfolioPARSED.replace(']', '');

  sessionStorage.setItem('favoritos', favoritosPARSED);
  sessionStorage.setItem('portfolio', portfolioPARSED);
  sessionStorage.setItem('historial', JSON.stringify(historial));
}

export const getFavoritos = () => {
  return sessionStorage.getItem('favoritos') || null;
}

export const getHistorial = () => {
  return sessionStorage.getItem('historial') || null;
}

export const getPortfolio = () => {
  return sessionStorage.getItem('portfolio') || null;
}

export function getMarketCAP(marketcap,locale) 
{
 var money=marketcap;
 var MAGNITUD='M';
 if(money>1000000000) { MAGNITUD='B'; }
 if(money>1000000000000) { MAGNITUD='T'; }

 

 if(MAGNITUD=='M') //150 000 000
 {
  money=money/1000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='B') //150 000 000 000
 {
  money=money/1000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='T') //150 000 000 000 000
 {
  money=money/1000000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
}

function parseData(parse) {
  //console.log("parse:"+parse)
	return function(d) 
  {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;
    //console.log("row:"+d.date)
		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");

//const parseDate = timeParse("%Y-%m-%d");
//const parseDateTime = timeParse("%Y-%m-%d %H:%M:%S");

export function getDataCSV(TICKER) {
  

const link = glovar.LINK_PROXY_COTIS_DOWNLOAD+TICKER+
"?period1=1613992913&period2=1695615313&interval=1d&events=history"


try { 
  
Papa.parse(link, {
	download: true,
  header: true,
      skipEmptyLines: true,
	step: function(row) {
    var rowx = {
      date:"",
    open:"",
    high:"",
    low:"",
    close:"",
    volume:"",
    split:"",
    dividend:"",
    absoluteChange:"",
    percentChange:""
    }
    //{"date":"2010-01-04T23:00:00.000Z","open":25.627344939513726,"high":25.83502196495549,
    //"low":25.452895407434543,"close":25.718722,"volume":49749600,"split":"","dividend":"","absoluteChange":"","percentChange":""}
    //{"Date":"2019-07-25","Open":"15.566667","High":"15.633333","Low":"15.036667","Close":"15.254667","Adj Close":"15.254667","Volume":"336274500"}
    //rowx.date=parseDate(rowx.date)
    rowx.date=row.data.Date//+"T23:00:00.000Z";
    rowx.date=parseDate(rowx.date);
    rowx.open=+row.data.Open;
    rowx.high=+row.data.High;
    rowx.low=+row.data.Low;
    rowx.close=+row.data.Close;
    rowx.volume=+row.data.Volume;
    rowx.split="";
    rowx.dividend="";
    rowx.absoluteChange="";
    rowx.percentChange="";

    //console.log(parseDate(rowx.date))
    //console.log("Row:", JSON.stringify(rowx));
    glovar.DATA_COTIS2.push(rowx)
		//console.log("Row:", JSON.stringify(row.data));
    //console.log(parseData(parseDate))
	},
	complete: results => { 
    /*results.data.map((d) => {
      //console.log("d")
    });*/
		//console.log("All done!");
    return glovar.DATA_COTIS2;
    //console.log(JSON.stringify(glovar.DATA_COTIS2))
	}
});

  /*var results =Papa.parse(link, {
    download: true,
    complete: results => { 
      console.log(results.data)
      glovar.DATA_COTIS2=results.data;
      //return results;
    }
})*/
}
catch(Exx) {}
//return results;

}

