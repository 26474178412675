
//require('dotenv').config()
//import dotenv from  'dotenv'
//import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
//dotenv.config()


import i18n from "../../i18n"

export function getMONEDADivisa(currency,quoteType)
{
 //console.log('currency:'+currency); 
 //console.log('quoteType:'+quoteType); 
 // (si) "quoteType":"EQUITY" "quoteType":"CURRENCY",
 //(no) "quoteType":"INDEX","quoteType":"FUTURE"
 try {
  if(quoteType=="INDEX" /*|| quoteType=="FUTURE"*/) { return("")}
  else {
    switch(currency)
    {
    case 'USD': return('$'); 
    case 'JPY': return('¥'); 
    case 'GBP': return('£'); 
    case 'GBp': return('p');
    case 'AUD': return('$'); 
    case 'NZD': return('$'); 
    case 'CHF': return('Fr'); 
    case 'HKD': return('HK$'); 
    case 'INR': return('₹'); 
    case 'BRL': return('R$'); 
    case 'ARS': return('$');
    case 'MXN': return('$'); 
    case 'CLP': return('$');
    case 'EUR': return('€'); 

    default: 
   return(' '); 
   }
  }
 } catch(Exxxxc) {return('€'); }
  
}

var URL_API_RSS2JSON= process.env.REACT_APP_URL_JSON;
var URL_RSS_TAGS_CINCODIAS="https://cincodias.elpais.com/tag/rss/";

export default 
{

  KEY_ADCOMP_320: process.env.REACT_APP_KEY_ADCOMP_320,
  KEY_ADCOMP_468: process.env.REACT_APP_KEY_ADCOMP_468, 
  LINK_PROXY_COTIS: process.env.REACT_APP_LINK_PROXY_COTIS,
  LINK_PROXY_COTIS_DOWNLOAD: process.env.REACT_APP_LINK_PROXY_COTIS_DOWNLOAD,

  KEY_ADCOMP_320_OK: process.env.REACT_APP_KEY_ADCOMP_320_OK,
  KEY_ADCOMP_468_OK: process.env.REACT_APP_KEY_ADCOMP_468_OK, 
  KEY_ADCOMP_600: process.env.REACT_APP_KEY_ADCOMP_600,
  KEY_ADCOMP_728 : process.env.REACT_APP_KEY_ADCOMP_728,
 

  SIZE_LESS:1,
  BACKGROUND_POPUP_NEWSLETTER: process.env.REACT_APP_BACKGROUND_POPUP_NEWSLETTER,
  BACKGROUND_POPUP_NEWSLETTER_MOBILE: process.env.REACT_APP_BACKGROUND_POPUP_NEWSLETTER_MOBILE,

  REF_EMAIL_SERVICE:process.env.REACT_APP_REF_EMAIL_SERVICE,
  REF_EMAIL_TEMPLATE:process.env.REACT_APP_REF_EMAIL_TEMPLATE,
  REF_EMAIL_KEY:process.env.REACT_APP_REF_EMAIL_KEY,

  ARRAY_COTIS_HOMEPAGE_1:process.env.REACT_APP_ARRAY_COTIS_HOMEPAGE_1,
  ARRAY_COTIS_HOMEPAGE_2:process.env.REACT_APP_ARRAY_COTIS_HOMEPAGE_2,
  ARRAY_COTIS_HOMEPAGE_3:process.env.REACT_APP_ARRAY_COTIS_HOMEPAGE_3,
  ARRAY_COTIS_HOMEPAGE_4:process.env.REACT_APP_ARRAY_COTIS_HOMEPAGE_4,
  ARRAY_COTIS_HOMEPAGE_5:process.env.REACT_APP_ARRAY_COTIS_HOMEPAGE_5,

  MOBILE_ARRAY_COTIS_HOMEPAGE_1:process.env.REACT_APP_MOBILE_ARRAY_COTIS_HOMEPAGE_1,
  MOBILE_ARRAY_COTIS_HOMEPAGE_2:process.env.REACT_APP_MOBILE_ARRAY_COTIS_HOMEPAGE_2,
  MOBILE_ARRAY_COTIS_HOMEPAGE_3:process.env.REACT_APP_MOBILE_ARRAY_COTIS_HOMEPAGE_3,
  MOBILE_ARRAY_COTIS_HOMEPAGE_4:process.env.REACT_APP_MOBILE_ARRAY_COTIS_HOMEPAGE_4,
  MOBILE_ARRAY_COTIS_HOMEPAGE_5:process.env.REACT_APP_MOBILE_ARRAY_COTIS_HOMEPAGE_5,


  SHOW_MOBILE_LAYOUT_SHORTTERM:process.env.REACT_APP_SHOW_MOBILE_LAYOUT_SHORTTERM === 'true',
  //SHOW_MOBILE_LAYOUT_SHORTTERM:process.env.REACT_APP_SHOW_MOBILE_LAYOUT_SHORTTERM,

  TOPTRADERS_ACTIVE:false,
  APP_DOWNLOAD_ACTIVE:false,
  MAX_CARROUSEL_TICKERS_MOBILE:6,
  MAX_CARROUSEL_TICKERS_DESKTOP:10,

  FIRST_RENDER:true,
  //Variables de entorno
  HOME_URL:process.env.REACT_APP_HOME_URL,
 
  USER_API_POST:process.env.REACT_APP_USER_API_POST,
  PASS_API_POST:process.env.REACT_APP_PASS_API_POST,
  USER_API:process.env.REACT_APP_USER_API, 
  PASS_API:process.env.REACT_APP_PASS_API, 
  ARRAY_COTIS_MORE_POPULAR:process.env.REACT_APP_ARRAY_COTIS_MORE_POPULAR, //"^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X",
  
  URL_FETCH_COTIS:process.env.REACT_APP_URL_FETCH_COTIS,
  URL_FETCH_BT:process.env.REACT_APP_URL_FETCH_BT,

  URL_FETCH_COTIS_SEARCH:process.env.REACT_APP_URL_FETCH_COTIS_SEARCH,

  URL_FETCH_SEARCH_NEWS:process.env.REACT_APP_URL_FETCH_SEARCH_NEWS,

  REGISTER_ONLINE:false,//process.env.REACT_APP_REGISTER_ONLINE,

  
API_KEY_RSS2JSON: process.env.REACT_APP_API_KEY_JSON, 
URL_API_RSS2JSON: process.env.REACT_APP_URL_JSON,


  URL_FETCH_COTIS_HISTORY:process.env.REACT_APP_URL_FETCH_COTIS_HISTORY,
  URL_cincodiasRssFeed:process.env.REACT_APP_URL_cincodiasRssFeed,
  URL_cincodiasPortadaRssFeed:process.env.REACT_APP_URL_cincodiasPortadaRssFeed,
  URL_BACKEND_PROD:process.env.REACT_APP_URL_BACKEND_PROD,
  URL_PLAYSTORE: process.env.REACT_APP_URL_PLAYSTORE,
  URL_APPSTORE: process.env.REACT_APP_URL_APPSTORE,
  LINK_URL_TIPOS_SUSCRIPCION_ES: process.env.REACT_APP_LINK_URL_TIPOS_SUSCRIPCION_ES,
  LINK_URL_TIPOS_SUSCRIPCION_EN: process.env.REACT_APP_LINK_URL_TIPOS_SUSCRIPCION_EN,
  MAXMILIS_REFRESH_TOPTRADERSLIST: process.env.REACT_APP_MAXMILIS_REFRESH_TOPTRADERSLIST,
  USER_API_COTIS:process.env.REACT_APP_USER_API_COTIS,
  PASS_API_COTIS:process.env.REACT_APP_PASS_API_COTIS,
  MAXMILIS_REFRESH_MORE_POPULAR:process.env.REACT_APP_MAXMILIS_REFRESH_MORE_POPULAR,
  MAXMILIS_REFRESH_MY_WATCHLIST:process.env.REACT_APP_MAXMILIS_REFRESH_MY_WATCHLIST,  
  MAXMILIS_REFRESH_MY_PORTFOLIO:process.env.REACT_APP_MAXMILIS_REFRESH_MY_PORTFOLIO,
  //////////////////////


  COOKIE_KEY_ACCEPTED: 'cookiesAccepted',
  COOKIE_KEY_ACCEPTED_ANALYTICS: 'cookiesAcceptedAnalytics',
  COOKIE_KEY_ACCEPTED_ADVERTISING: 'cookiesAcceptedAdvertising',
  COOKIE_KEY_REJECTED: 'cookiesRejected',
  
  PATH_TERMS_POLICY:   "/terms",
  PATH_COOKIES_POLICY: "/cookiespolicy",
  PATH_PRIVACY_POLICY: "/privacypolicy",

  URL_TERMS_POLICY: process.env.PUBLIC_URL+"/terms",
  URL_COOKIES_POLICY: process.env.PUBLIC_URL+"/cookiespolicy",
  URL_PRIVACY_POLICY: process.env.PUBLIC_URL+"/privacypolicy",

  //FORCE_COOKIES_CONFIG:false,
  COOKIE_ACCEPTED:false,
  COOKIE_ACCEPTED_ANALYTICS:false,
  COOKIE_ACCEPTED_ADVERTISING:false,
  COOKIE_REJECTED:false,

  LAST_ELEMENT_SEARCH:[],
  READY_TO_READ:false,

  DATA_COTIS2:[],

  USER_LANG:'en-US',
  USER_LANG_PRO1:'EN',
  DEFAULT_INDEX_TABS_FOLLOWS:0,
  MODE_FOLLOWERS:1,
  MODE_FOLLOWING:2,
  MODE_PRE_FOLLOWERS:3,
  MODE_PRE_FOLLOWING:4,
  MODE_TITLE_FOLLOWERS:5,
  MODE_TITLE_FOLLOWING:6,
  MODE_TITLE_PRE_FOLLOWERS:7,
  MODE_TITLE_PRE_FOLLOWING:8,

  NUM_FOLLOWERS:0,
  NUM_FOLLOWING:0,
  NUM_PRE_FOLLOWERS:0,
  NUM_PRE_FOLLOWING:0,
  DONT_REFRESH_FETCH_TODO_USER:false,
  SHOW_MY_PROFILE:null,
  TOAST_PENDIENTE_ID:0,
  STATUS_FOLLOW:0,
  lastTimeMOREPOPULAR_COTIS:0,
  lastTimeMYWATCHLIST_COTIS:0,
  lastTimeMYPORTFOLIO_COTIS:0,
  MOREPOPULAR_COTIS: [],
  MYWATCHLIST_COTIS: [],
  MYPORTFOLIO_COTIS: [],


  SHOW_PUBLIC_PROFILE_USER:false,
  SHOW_PUBLIC_PROFILE_USER_INFO:false,
  SHOW_PUBLIC_PROFILE_BTNFOLLOW:true,

  SHOW_PUBLIC_CARTERA:false,
  SHOW_PUBLIC_CARTERA_TIT_BENEF:true,
  SHOW_PUBLIC_CARTERA_ONLY_FOLLOW:false,
  SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW:false,
  SHOW_PUBLIC_HISTORIAL:false,
  SHOW_PUBLIC_HISTORIAL_TIT_BENEF:true,
  SHOW_TOPTRADER_PROFILE:[],

  lastTimeTOPTRADERSLIST:0,
  REFRESH_FRONTUSER:false,
  GLOBAL_CURRENT_PARTDIVISAS:[],
  CURRENT_FAVORITOS:[],
  CURRENT_HISTORIAL:[],
  CURRENT_PORTFOLIO:[], 
  USER_LOADED:false,
  MODE_USER:0,
  MODE_USER_WEB:0,
  TOPTRADERSLIST: [],
  APP_STATE_ACTIVE:"active",
  APP_STATE_BACKGROUND:"background",
  APP_STATE_STATE:"",
  APP_STATE_RUNNING:false, 
  FORCE_REFRESH_ELEMENT:0,
  
  USER_LOADED:false,
  CURRENT_MENU_ID:5,
  SHOWING_FULL_CHART:false,
  OFFSET_Y_FULLSCREEN_CHART:160,//130,
  CHART_PERIOD1:process.env.REACT_APP_CHART_PERIOD1,
  CHART_PERIOD2:process.env.REACT_APP_CHART_PERIOD2,

  
  productDetails: {
    title: "product title",
    stock: "55"
  },

  REFRESH_FILTRES:false,
  MY_FILTERS_LIST:[],

   LAST_SEARCH: [], 
   

   TICKER_INDEX_PRINCIPAL: [

    { label: "DOW JONES", value: "DOW JONES", color: "#5243AA" },
    { label: "NASDAQ 100", value: "^NDX,ATVI,ADBE,ADP,ABNB,ALXN,ALGN,GOOGL,GOOG,AMZN,AMD,AEP,AMGN,ADI,ANSS,AAPL,AMAT,ASML,TEAM,ADSK,BIDU,BIIB,BKNG,AVGO,CDNS,CDW,CHTR,CHKP,CTAS,"+
    "CSCO,CTSH,CMCSA,CPRT,COST,CSX,DXCM,DOCU,DLTR,EBAY,EA,EXC,META,FAST,FISV,FOXA,FOX,GILD,IDXX,ILMN,INCY,INTC,INTU,ISRG,JD,KDP,KLAC,KHC,LRCX,LULU,"+
    "MAR,MRVL,MTCH,MXIM,MELI,MCHP,MU,MSFT,MRNA,MDLZ,MNST,NTES,NFLX,NVDA,NXPI,OKTA,ORLY,PCAR,PAYX,PYPL,PTON,PEP,PDD,QCOM,REGN,ROST,SGEN,SIRI,"+		 
    "SWKS,SPLK,SBUX,SNPS,TMUS,TSLA,TXN,TCOM,VRSN,VRSK,VRTX,WBA,WDAY,XEL,ZS,ZM", color: "#5243AA" },
    { label: "S&P 500", value: "S&P 500", color: "#5243AA" },

    { label: "IBEX 35", value: "IBEX 35", color: "#0052CC" },
    
    { label: "CAC 40", value: "CAC 40" },

    { label: "DAX 40", value: "DAX 40" },

    { label: "FTSE 100", value: "FTSE 100", color: "#0052CC" },
  ],


  TICKER_INDEX_USA: [
    { label: "DOW JONES", value: "DOW JONES", color: "#5243AA" },
    { label: "NASDAQ 100", value: "^NDX,ATVI,ADBE,ADP,ABNB,ALXN,ALGN,GOOGL,GOOG,AMZN,AMD,AEP,AMGN,ADI,ANSS,AAPL,AMAT,ASML,TEAM,ADSK,BIDU,BIIB,BKNG,AVGO,CDNS,CDW,CHTR,CHKP,CTAS,"+
    "CSCO,CTSH,CMCSA,CPRT,COST,CSX,DXCM,DOCU,DLTR,EBAY,EA,EXC,META,FAST,FISV,FOXA,FOX,GILD,IDXX,ILMN,INCY,INTC,INTU,ISRG,JD,KDP,KLAC,KHC,LRCX,LULU,"+
    "MAR,MRVL,MTCH,MXIM,MELI,MCHP,MU,MSFT,MRNA,MDLZ,MNST,NTES,NFLX,NVDA,NXPI,OKTA,ORLY,PCAR,PAYX,PYPL,PTON,PEP,PDD,QCOM,REGN,ROST,SGEN,SIRI,"+		 
    "SWKS,SPLK,SBUX,SNPS,TMUS,TSLA,TXN,TCOM,VRSN,VRSK,VRTX,WBA,WDAY,XEL,ZS,ZM", color: "#5243AA" },
    { label: "S&P 500", value: "S&P 500", color: "#5243AA" },
  ],

  TICKER_INDEX_EU: [
    { label: "EUROSTOXX 50", value: "EUROSTOXX 50", color: "#0052CC" },
  ],


   TICKER_INDEX_SPAIN: [
    { label: "IBEX 35", value: "IBEX 35", color: "#0052CC" },

    { label: "MERCADO CONTINUO",
      value: "INDC.MC,ANA.MC,ACX.MC,ACS.MC,ADZ.MC,AEDAS.MC,AENA.MC,AIR.MC,AI.MC,ALNT.MC,ALB.MC,ALM.MC,AMS.MC,AMP.MC,EAT.MC,APPS.MC,MTS.MC,ARM.MC,"+
             "A3M.MC,ADX.MC,AZK.MC,SAB.MC,BKT.MC,BBVA.MC,BKY.MC,RIO.MC,CABK.MC,GCO.MC,CLNX.MC,CIE.MC,CBAV.MC,CCEP.MC,COL.MC,CAF.MC,OLE.MC,DIA.MC,"+
             "MDF.MC,EBRO.MC,EDR.MC,ENO.MC,GSJ.MC,ENG.MC,ENC.MC,ELE.MC,ECR.MC,EZE.MC,FAE.MC,FCC.MC,FER.MC,FDR.MC,GALQ.MC,GEST.MC,DOM.MC,GRE.MC,GRF.MC,"+
             "GRF-P.MC,IAG.MC,IBE.MC,IBG.MC,ITX.MC,IDR.MC,ISUR.MC,LRE.MC,LDA.MC,LGT.MC,LOG.MC,MAP.MC,TL5.MC,MEL.MC,MRL.MC,MVC.MC,MCM.MC,MTB.MC,NTGY.MC,"+
             "NTH.MC,HOME.MC,NHH.MC,NEA.MC,NXT.MC,NYE.MC,OHLA.MC,ORY.MC,PVA.MC,PHM.MC,PRM.MC,PRS.MC,PSG.MC,CASH.MC,RLIA.MC,RED.MC,"+
             "RJF.MC,R4.MC,REN.MC,REP.MC,ROVI.MC,SCYR.MC,SAN.MC,SGRE.MC,SLR.MC,TLGO.MC,TRE.MC,TEF.MC,TUB.MC,TRG.MC,UNI.MC,UBS.MC,VID.MC,VIS.MC,VOC.MC", color: "#00529C" },
   ],
      
   TICKER_INDEX_FRANCE: [
    { label: "CAC 40", value: "CAC 40" },

    { label: "SBF 120", value: "SBF 120" },

    { label: "CAC ALLSHARE", value: "CAC ALLSHARE", color: "#0052CC" },
  ],

  TICKER_INDEX_GERMANY: [
    { label: "DAX 40", value: "DAX 40" },
    { label: "TECDAX", value: "TECDAX_STOCKS" },
    { label: "MDAX", value: "MDAX_STOCKS" },
    { label: "SDAX", value: "SDAX_STOCKS" },
    { label: "DAX ALL SHARE", value: "DAX_ALL_SHARE_STOCKS" },
    { label: "TECDAX SHARE", value: "TECDAX_SHARE_STOCKS" },
  ],

  TICKER_INDEX_UK: [
    { label: "FTSE 100", value: "FTSE 100", color: "#0052CC" },
  ],

 

  technical_criteria: [
    { label: i18n.t("dist_50"),//"Distancia media móvil 50 sesiones", 
      value: "fiftyDayAverageChangePercent", 
      name: "Average 50s change", unidad:"%",
      multi:100,
      descript: i18n.t("dist_50_text"),//"Distancia entre la cotización y su media móvil de 50 sesiones.", 
      min:0, max:0,
      color: "#0052CC" 
    },
    { label: i18n.t("dist_200"),//"Distancia media móvil 200 sesiones",
      value: "twoHundredDayAverageChangePercent",
      name: "Average 200s change", unidad:"%",
      multi:100,
      descript: i18n.t("dist_200_text"),//""Distancia entre la cotización y su media móvil de 200 sesiones.",
      min:0, max:0,
      color: "#5243AA" },

      

    { label: i18n.t("incr_vol"),//"Incremento volumen a corto plazo", 
      value: "averageDailyVolume10Day,averageDailyVolume3Month",  
      name: "Short-term volume incr", unidad:"%",
      multi:100,
      descript: i18n.t("incr_vol_text"),//""Incremento en el volumen medio registrado entre sus medias de 10 dias y 3 meses.", 
      min:0, max:0,
      color: "#FF5630" },


    { label: i18n.t("var_min52"),//""Variación respecto mínimo 52 sem", 
      value: "fiftyTwoWeekLowChangePercent", 
      name: "Chg from 52wk low", unidad:"%",
      multi:100,
      descript: i18n.t("var_min52_text"),//"Variación de la cotización respecto al mínimo registrado durante las últimas 52 semanas.", 
      min:0, max:0,
      color: "#FF8B00" },


    { label: i18n.t("var_max52"),//""Variación respecto máximo 52 sem", 
      value: "fiftyTwoWeekHighChangePercent", 
      name: "Chg from 52wk high", unidad:"%",
      multi:100,
      descript: i18n.t("var_max52_text"),//"Variación de la cotización respecto al mínimo registrado durante las últimas 52 semanas.", 
      min:0, max:0,
      color: "#FFC400" },
  ],

 
  fundamental_criteria: [
    { label: "PE Ratio (TTM)", value: "trailingPE",  name: "PE Ratio (TTM)", unidad:"",  multi:1, 
      descript: i18n.t("pe_ratio_text"), //"Indica el número de veces que el beneficio neto de una empresa está incluido en el precio de una de sus acciones.", 
      min:0, max:0,
      color: "#0052CC" },


    { label: "EPS", value: "epsTrailingTwelveMonths", multi:1, name: "EPS", unidad:"",  
      descript: i18n.t("eps_max52_text"),//"Indica el beneficio neto por acción obtenido por la empresa en el último ejercicio contable.",
      min:0, max:0,
      color: "#5243AA" },


    { label: i18n.t("div_rent_div"),//"Dividendo/Rentabilidad dividendo", 
      multi:1,  value: "trailingAnnualDividendRate,trailingAnnualDividendYield",   name: "Div/Rent", unidad:"",  
      descript: i18n.t("div_rent_div_text"), //" Dividendo por acción y rentabilidad resultante de dividir el dividendo entre el precio de la acción", 
      min:0, max:0,
      color: "#FF5630" },


    { label: "Price To Book", value: "priceToBook", name: "Price To Book", unidad:"",    multi:1, 
      descript:  i18n.t("price_book_text"),//"Indica el ratio entre la cotización y el valor en libros por acción de dicha empresa.",
       min:0, max:0,  
      color: "#FF8B00" },

      { label: "Market Cap", value: "marketCap", name: "Market Cap", unidad:"",    multi:0.000001, 
      descript: i18n.t("market_cap_text"),//"Capitalización bursátil de la empresa resultante de multiplicar su cotización por el número total de acciones en circulación.", 
      min:0, max:0,  
      color: "#FF8B00" },

      { label: "Volume", value: "regularMarketVolume", name: "Volume", unidad:"",    multi:1, 
      descript: i18n.t("volume_text"),//"Indica el número de acciones objeto de compraventa durante un determinado período de tiempo.",
       min:0, max:0,  
      color: "#FF8B00" },

    { label: "Book Value", value: "bookValue", name: "Book Value", unidad:"",  multi:1, 
    descript: i18n.t("book_value_text"),//"Ratio entre el valor del capital contable total de una empresa dividido entre su número de acciones en circulación", 
    min:0, max:0,  
    color: "#FF8B00" },

    //{ label: "Fecha presentación resultados", value: "earningsTimestamp,earningsTimestampStart,earningsTimestampEnd", color: "#FFC400" },
    { label:  i18n.t("avg_anal_rating"),//"Media Rating Analistas", 
    name: "Rating Analysts", unidad:"",   multi:1, value: "averageAnalystRating",
    descript: "[1-Strong Buy  2-Buy  3-Hold  4-Under-perfom  5-Sell]", 
    min:0, max:0,  
     color: "#FFC400" },
  ],

  //default_criteria: [ "trailingPE", "epsTrailingTwelveMonths", /*"trailingAnnualDividendYield",*/ "regularMarketVolume", "marketCap" ],


  default_criteria: [
    { label: "PE Ratio (TTM)", value: "trailingPE",  name: "PE Ratio (TTM)", unidad:"",  multi:1, 
      descript: i18n.t("pe_ratio_text"),
      min:0, max:0,
      color: "#0052CC" },


    { label: "EPS", value: "epsTrailingTwelveMonths", multi:1, name: "EPS", unidad:"",  
      descript: i18n.t("eps_max52_text"),min:0, max:0,
      color: "#5243AA" },


    { label: i18n.t("div_rent_div"),
      multi:1,  value: "trailingAnnualDividendRate,trailingAnnualDividendYield",   name: "Div/Rent", unidad:"",  
      descript: i18n.t("div_rent_div_text"),
       min:0, max:0,
      color: "#FF5630" },


    { label: "Price To Book", value: "priceToBook", name: "Price To Book", unidad:"",    multi:1, 
      descript: i18n.t("price_book_text"), 
       min:0, max:0,  
      color: "#FF8B00" },

      { label: "Market Cap", value: "marketCap", name: "Market Cap", unidad:"",    multi:0.000001, 
      descript: i18n.t("market_cap_text"), 
      min:0, max:0,  
      color: "#FF8B00" },

      { label: "Volume", value: "regularMarketVolume", name: "Volume", unidad:"",    multi:1, 
      descript: i18n.t("volume_text"),
       min:0, max:0,  
      color: "#FF8B00" },

    { label: "Book Value", value: "bookValue", name: "Book Value", unidad:"",  multi:1, 
    descript: i18n.t("book_value_text"),//"Indica el número de veces que el beneficio neto de una empresa está incluido en el precio de una de sus acciones.", 
    min:0, max:0,  
    color: "#FF8B00" },

    //{ label: "Fecha presentación resultados", value: "earningsTimestamp,earningsTimestampStart,earningsTimestampEnd", color: "#FFC400" },
    { label:  i18n.t("avg_anal_rating"),//"Media Rating Analistas", 
    name: "Rating Analysts", unidad:"",   multi:1, value: "averageAnalystRating",
    descript: "[1-Strong Buy  2-Buy  3-Hold  4-Under-perfom  5-Sell]", min:0, max:0,  
     color: "#FFC400" },
  ],

  DEFAULT_NUMBER: 81311.16381,


  /*
  -Distancia media móvil 50 sesiones":fiftyDayAverage":139.1536,"fiftyDayAverageChange":6.7763977,"fiftyDayAverageChangePercent":0.04869725,
-Diferencia respecto media móvil 200:twoHundredDayAverage":148.38976,"twoHundredDayAverageChange":2.4597626,"twoHundredDayAverageChangePercent":0.016576365,
-Incremento de volumen c/p: "averageDailyVolume3Month":79021403, "averageDailyVolume10Day":66880940,
-Variación 52 min semanas: "fiftyTwoWeekLowChangePercent":0.17524357, "fiftyTwoWeekLowChange":21.759995,
-Variación 52 max semanas: "fiftyTwoWeekHighChangePercent":-0.18751745, "fiftyTwoWeekHighChange":-33.680008,

Fundamentales:
-PE Ratio (TTM)	: trailingPE
-EPS epsTrailingTwelveMonths":6.11
-Dividendo/Rentabilidad divendo: "trailingAnnualDividendRate":0.9, "trailingAnnualDividendYield":0.006251736
-priceToBook "priceToBook":45.918816,
-bookValue "bookValue":3.178,
-Fecha presentación resultados: earningsTimestamp":"2023-02-02T21:00:00.000Z" "earningsTimestampStart":"2023-02-02T21:00:00.000Z","earningsTimestampEnd":"20230202T21:00:00.000Z",
-averageAnalystRating":"2.0 - Buy
*/


  ARRAY_TAGS_ES: [ 

    { name:'IBEX 35', symbol:'^IBEX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ibex_35/", 
      relatedTickers:["SAN.MC","IBE.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "ITX.MC", "CABK.MC","^STOXX50E"] },

      { name:'Microsoft', symbol:'MSFT', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"microsoft/", 
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "TSLA", "AAPL", "^NDX","NVDA"] },

    
    /*  
    { name:'Meta Platforms', symbol:'META', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"meta_platforms/" },
    { name:'Microsoft', symbol:'MSFT', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"microsoft/" },
    { name:'Check Point', symbol:'CHKP', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"check_point/" },
      */

      /*{ name:'Apple', symbol:'AAPL', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"apple_computer/" },*/
      { name:'Almirall', symbol:'ALM.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"almirall/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     /* { name:'Amazon', symbol:'AMZN', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amazon/" },
      { name:'Adobe', symbol:'ADBE', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"adobe/" },*/
      { name:'Acciona', symbol:'ANA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acciona/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Aena', symbol:'AENA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"aena_aeropuertos/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'ACS', symbol:'ACS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acs/", 
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "IAG.MC", "FER.MC", "ANA.MC","^STOXX50E"] },
      { name:'Amadeus', symbol:'AMS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amadeus/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "AENA.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Amper', symbol:'AMP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"amper/",
        relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Alphabet', symbol:'GOOG', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"alphabet/" },
      { name:'Acerinox', symbol:'ACX.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"acerinox/",
      relatedTickers:["^IBEX","SAN.MC", "MTS.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'ArcelorMittal', symbol:'MTS.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"arcelormittal/",
      relatedTickers:["^IBEX","SAN.MC", "ACX.MC", "REP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Bankinter', symbol:'BKT.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"bankinter/" ,
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Banco de Sabadell', symbol:'SAB.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"banco_de_sabadell/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "CABK.MC", "BKT.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Santander', symbol:'SAN.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"banco_de_santander/",
      relatedTickers:["^IBEX","BKT.MC", "BBVA.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'BBVA', symbol:'BBVA.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"bbva_banco_bilbao_vizcaya_argentaria/",
      relatedTickers:["^IBEX","SAN.MC", "BKT.MC", "CABK.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },

      { name:'Caixabank', symbol:'CABK.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"caixabank/",
      relatedTickers:["^IBEX","SAN.MC", "BKT.MC", "CABK.MC", "SAB.MC", "TEF.MC", "BBVA.MC","^STOXX50E"] },

      //{ name:'Coca Cola', symbol:'KO', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"coca_cola/" },
      { name:'Cellnex', symbol:'CLNX.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"cellnex_telecom/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "FER.MC", "IAG.MC", "TEF.MC", "IND.MC","^STOXX50E"] },
      { name:'Enagas', symbol:'ENG.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"enagas/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "NTG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Endesa', symbol:'ELE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"endesa/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "NTG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Easyjet', symbol:'EZJ.L', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"easy_jet/",
      relatedTickers:["^IBEX","IAG.MC", "AF.PA", "ADP.PA", "AENA.MC", "LHA.DE", "CABK.MC","^STOXX50E"] },
      { name:'Ezentis', symbol:'EZE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ezentis_energia/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "AMP.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Ercros', symbol:'ECR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ercros/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Fluidra', symbol:'FDR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"fluidra/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "EZE.MC", "IAG.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Ferrovial', symbol:'FER.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ferrovial/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ACS.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     // { name:'Ferrari', symbol:'RACE', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"ferrari/" },
      { name:'Naturgy', symbol:'NTGY.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"gas_natural_fenosa/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ELE.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
     // { name:'Google', symbol:'GOOG', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"google" },
     // { name:'Intel', symbol:'INTL', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"intel/" },
      { name:'Indra', symbol:'IDR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"indra/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'IAG', symbol:'IAG.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"iag_international_airlines_group/",
      relatedTickers:["^IBEX","EZJ.L", "AF.PA", "ADP.PA", "AENA.MC", "LHA.DE", "CABK.MC","^STOXX50E"] },
      { name:'Iberdrola', symbol:'IBE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"iberdrola/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "RED.MC", "ELE.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },

    

      { name:'Colonial', symbol:'COL.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"inmobiliaria_colonial/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "FER.MC", "ANA.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Laboratios Rovi', symbol:'ROVI.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"laboratorios_rovi/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "GRF.MC", "ALM.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Grifols', symbol:'GRF.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"laboratorios_grifols/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ALM.MC", "ROVI.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Mapfre', symbol:'MAP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"mapfre/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Moderna', symbol:'MRNA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"moderna/" },
      { name:'Melia', symbol:'MEL.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"melia/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Netflix', symbol:'NFLX', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"netflix/" },
      //{ name:'NVIDIA', symbol:'NVDA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"nvidia/" },
      { name:'Orange', symbol:'ORA.PA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"orange/",
      relatedTickers:["^IBEX","DTE.DE", "VOD.L", "T", "VZ", "TEF.MC", "TMUS","^STOXX50E"] },
      { name:'Pharmamar', symbol:'PHM.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"pharmamar/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "BKT.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      //{ name:'Qualcomm', symbol:'QCOM', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"qualcomm/" },
      { name:'Repsol', symbol:'REP.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"repsol_petroleo/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "IBE.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'REE', symbol:'RED.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"red_electrica_telecomunicaciones/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ENG.MC", "SAB.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Samsung', symbol:'005930.KS', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"samsung/",
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "TSLA", "MSFT","AAPL", "^NDX","NVDA"] },
      { name:'Sacyr', symbol:'SYCR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"sacyr/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "ANA.MC", "FER.MC", "TEF.MC", "CABK.MC","^STOXX50E"] },
      { name:'Solaria', symbol:'SLR.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"solaria/",
      relatedTickers:["^IBEX","SAN.MC", "BBVA.MC", "REP.MC", "ADX.MC", "TEF.MC", "IBE.MC","^STOXX50E"] },
      { name:'Siemens Gamesa', symbol:'SGRE.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"siemens_gamesa/" },
     // { name:'Symantec', symbol:'CHKP', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
     // rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"symantec/" },
      //{ name:'TSMC', symbol:'TSMC34.SA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      //rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"tsmc_taiwanese_semiconductor_manufacturing/" },
     { name:'Tesla', symbol:'TSLA', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
      rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"tesla_motors/", 
      relatedTickers:["GOOGL","AAPL", "META", "^GSPC", "COMP", "MSFT", "AAPL", "^NDX","NVDA"] },

{ name:'Telefonica', symbol:'TEF.MC', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"telefonica/",
relatedTickers:["^IBEX","DTE.DE", "VOD.L", "T", "VZ", "ORA.PA", "TMUS","^STOXX50E"] },
{ name:'Vodafone', symbol:'VOD.L', rss_id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'CincoDías', 
rss_url : URL_API_RSS2JSON+URL_RSS_TAGS_CINCODIAS+"vodafone/",
relatedTickers:["^IBEX","DTE.DE", "ORA.PA", "T", "VZ", "TEF.MC", "TMUS","^STOXX50E"] },



  ],

  ARRAY_TAGS_FR: [ 

    { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'BFM Bourse', 
      rss_url : URL_API_RSS2JSON+"https://www.tradingsat.com/rssbourse.xml", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      /*{ name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'BFMBusiness Actualites', 
      rss_url : URL_API_RSS2JSON+"https://bfmbusiness.bfmtv.com/rss/info/flux-rss/flux-toutes-les-actualites/", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },*/

     /* { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Titres', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/titres.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },*/


     { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'20minutes economie', 
      rss_url : URL_API_RSS2JSON+"https://www.20minutes.fr/feeds/rss-economie.xml", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Marches', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/economie/bourse/marches.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'FranceTV Enterprises', 
      rss_url : URL_API_RSS2JSON+"https://www.francetvinfo.fr/economie/entreprises.rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'ABC Bourse', 
      rss_url : URL_API_RSS2JSON+"https://www.abcbourse.com/rss/displaynewsrss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },

      { name:'CAC 40', symbol:'^FCHI ^SBF120', id:2, lang:'FR', checked:true, canalID:4, rss_id : 3,  rss_source:'Capital', 
      rss_url : URL_API_RSS2JSON+"https://www.capital.fr/rss", 
      relatedTickers:["^FCHI","EURUSD=X", "MC.PA", "SAN.PA", "OR.PA", "BN.PA", "CS.PA", "CA.PA","ACA.PA","GLE.PA"] },


        
      /*
      { id:1, lang:'ES', checked:true, canalID:24, rss_id : 9, rss_source:'Le Monde Une',   rss_url : 'https://www.lemonde.fr/rss/une.xml' },
      { id:2, lang:'ES', checked:true, canalID:25, rss_id : 9, rss_source:'Le Monde En continu',   rss_url : 'https://www.lemonde.fr/rss/en_continu.xml' },
      { id:3, lang:'ES', checked:true, canalID:26, rss_id : 9, rss_source:'Le Monde Economie',   rss_url : 'https://www.lemonde.fr/economie/rss_full.xml' },
      { id:4, lang:'ES', checked:false, canalID:27, rss_id : 9, rss_source:'Le Monde Enterprises',   rss_url : 'https://www.lemonde.fr/entreprises/rss_full.xml' },
      { id:5, lang:'ES', checked:false, canalID:28, rss_id : 9, rss_source:'Le Monde Argent',   rss_url : 'https://www.lemonde.fr/argent/rss_full.xml' },
  
      //{ id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFMBusiness Actualites',   rss_url : 'https://bfmbusiness.bfmtv.com/rss/info/flux-rss/flux-toutes-les-actualites/' },
      { id:6, lang:'ES', checked:true, canalID:3, rss_id : 3, rss_source:'BFM Bourse',   rss_url : 'https://www.tradingsat.com/rssbourse.xml' },
      { id:7, lang:'ES', checked:false, canalID:29, rss_id : 3, rss_source:'ABC Bourse',   rss_url : 'https://www.abcbourse.com/rss/displaynewsrss' },
      { id:8, lang:'ES', checked:true, canalID:30, rss_id : 3, rss_source:'Capital',   rss_url : 'https://www.capital.fr/rss' },
      { id:9, lang:'ES', checked:true, canalID:4, rss_id : 3, rss_source:'20minutes economie',   rss_url : 'https://www.20minutes.fr/feeds/rss-economie.xml' },
      { id:10, lang:'ES', checked:true, canalID:5, rss_id : 3, rss_source:'FranceTV Info',   rss_url : 'https://www.francetvinfo.fr/economie.rss' },
      { id:11, lang:'ES', checked:true, canalID:6, rss_id : 3, rss_source:'FranceTV Titres',   rss_url : 'https://www.francetvinfo.fr/titres.rss' },
      //{ id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Marches',   rss_url : 'https://www.francetvinfo.fr/economie/bourse/marches.rss' },
      { id:12, lang:'ES', checked:true, canalID:7, rss_id : 3, rss_source:'FranceTV Enterprises',   rss_url : 'https://www.francetvinfo.fr/economie/entreprises.rss' },
      { id:13, lang:'ES', checked:true, canalID:8, rss_id : 3, rss_source:'FranceTV Monde',   rss_url : 'https://www.francetvinfo.fr/monde.rss' },
      { id:14, lang:'ES', checked:true, canalID:9, rss_id : 3, rss_source:'FranceTV France',   rss_url : 'https://www.francetvinfo.fr/france.rss' },
      */

  ],

  ARRAY_TAGS_DE: [ 

    { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel News', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 
   
      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Politik', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] },  
      
      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Wirtschaft', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/wirtschaft/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Ausland', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/ausland/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      /*{ name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Netzwelt', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/netzwelt/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, 

      { name:'DAX 40', symbol:'^GDAXI ^TECDAX ^MDAXI ^SDAXI ^ATX', id:2, lang:'ES', checked:true, canalID:4, rss_id : 3,  rss_source:'Spiegel Panorama', 
      rss_url : URL_API_RSS2JSON+"https://www.spiegel.de/panorama/index.rss", 
      relatedTickers:["^GDAXI","^ATX", "SAP.DE", "ALV.DE", "SIE.DE", "BAS.DE", "BAYN.DE", "BMW.DE","^STOXX50E", "TKA.VI"] }, */
    ],


 



  FLAGS_COUNTRIES_ID: [
    "/assets/flags/eu.png",
    "/assets/flags/spain.png",
    "/assets/flags/eu.png", 
    "/assets/flags/us.png", 
    "/assets/flags/france.png",
    "/assets/flags/germany.png",
    "/assets/flags/uk.png",
    "/assets/flags/italy.png", 
    "/assets/flags/japan.png",
    "/assets/flags/belgium.png",
    "/assets/flags/hongkong.png", 
    "/assets/flags/brazil.png", 
    "/assets/flags/mexican.png", 
    "/assets/flags/chile.png", 
    "/assets/flags/eu.png",
    "/assets/flags/argentina.png",
    "/assets/flags/canada.png",
    "/assets/flags/south_korea.png",
    "/assets/flags/newzealand.png",
    "/assets/flags/india.png",
    "/assets/flags/australia.png",
    "/assets/flags/norway.png",
    "/assets/flags/sweden.png",
    "/assets/flags/swiss.png",
    "/assets/flags/hungary.png",
    "/assets/flags/china.png",
    "/assets/flags/singapure.png",
    "/assets/flags/australia.png",
    "/assets/flags/philipines.png",
    "/assets/flags/thailand.png",
    "/assets/flags/southafrica.png",
    "/assets/flags/russia.png",
    "/assets/flags/indonesia.png",
    "/assets/flags/netherlands.png" 
    ],
  
  
  AVATAR_TOPTRADERS: [
    "prof1.jpg",
  "prof2.jpg",
  "prof3.jpg",
  "prof4.jpg",
  "prof5.jpg",
  "prof6.jpg",
  "prof7.jpg",
  "prof8.jpg",
  "prof9.jpg",
  "prof10.jpg",
  "prof11.jpg",
  "prof12.jpg",
  "prof13.jpg",
  "prof14.jpg",
  "prof15.jpg",
  "prof16.jpg",
  "prof17.jpg",
  "prof18.jpg",
  "prof19.jpg",
  "prof20.jpg",
  "prof21.jpg",
  "prof22.jpg",
  "prof23.jpg",
  "prof24.jpg",
  "prof25.jpg",
  "prof26.jpg",
  "prof27.jpg",
  "prof28.jpg",
  "prof29.jpg",
  "prof30.jpg",
  "prof31.jpg",
  ],

};
