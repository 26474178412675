import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
import ToptraderListItem from "./ToptraderListItem";
import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
import PortfolioTopTraderList from "./PortfolioTopTraderList";
//import MorePopularList from "./MorePopularList";
import ShowAssets from "./ShowAssets";

import './search.css';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  //Select,
} from '@chakra-ui/react'

import Select from 'react-select';

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import Papa from "papaparse"


import { Heading } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import {Container } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'



import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";


import i18n from "../../i18n"

import glovar from './glovar';
import eventBus from "../eventBus";

//import glovar from './components/content/glovar';

//import glovar from "./glovar";
//import gsap from "gsap";

export default ({ }) => {
  


  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCriteri, setSelectedCriteri] = useState(null);

  const [showSearch,setShowSearch] = useState(false);
  const [doSearch,setDoSearch] = useState(false);
  const [doRefresh,setDoRefresh] = useState(false);

  
  const [showPopPup,setshowPopPup] = useState(false);
  const [email,setEmail] = useState("");

  /*
  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }*/

  function doOrderBy(typeOrder) {
    //console.log("typeOrder:"+typeOrder);
    setDoSearch(true);
    setShowSearch(true);
    setDoRefresh(doRefresh);
    //console.log("refresh!!!")

  }
  function handleIndex(selectedIndex) {
    
    setSelectedIndex(selectedIndex);
    if(doSearch) {setDoSearch(false); }
    setDoRefresh(false);
    //if(showSearch) {setShowSearch(false); }
    /*this.setState({ selectedIndex, }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );*/
  };



    const { todoTopTraders, isFetchingTopTraders } = useFetchTodoToptraders()//"IAG.MC,EURUSD=X")

    //const  todoTopTraders = useFetchTodoToptraders()
    //glovar.TOPTRADERSLIST=todoTopTraders
      
  //Slide click
  const slide = (shift) => {
    //console.log("scrollX:"+scrollX)
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  /*const anim = (e) => {
    //gsap.from(e.target, { scale: 1 });
    //gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    //gsap.from(e.target, { scale: 1.5 });
    //gsap.to(e.target, { scale: 1 });
  };*/

  function sendEventGA (category, action1, label) {
    try {
      //console.log("category:"+category);
      //console.log("action:"+action);

      var ACTION_LIST=""; //category;
      var CRITERI_LIST=""; //category;

      //console.log("LABEL:"+JSON.stringify(label));

      for(let j=0;j<action1.length;j++)
     {
      //console.log(j+" ACTION:"+JSON.stringify(action[j].label))
      ACTION_LIST=ACTION_LIST+"_"+action1[j].label.substring(0, 3);
     }

     //console.log("ACTION_LIST:"+ACTION_LIST);

     for(let j=0;j<label.length;j++)
     {
      //console.log(j+" label:"+JSON.stringify(label[j].label))
      CRITERI_LIST=CRITERI_LIST+"_"+label[j].label.substring(0, 3);
     }

    //console.log("CRITERI_LIST:"+CRITERI_LIST);

    var action=category+CRITERI_LIST+ACTION_LIST;
    var LABEL_GA=CRITERI_LIST+"_"+ACTION_LIST;

    //console.log("ACTION_GA:"+action);
      eventBus.dispatch("EventGA", { category, action, LABEL_GA});
    }
    catch(Exx) {}
  }


  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const groupedOptions = [

    {
      label: "Principales",
      options:  glovar.TICKER_INDEX_PRINCIPAL
    },
    {
      label: "USA",
      options:  glovar.TICKER_INDEX_USA
    }, 
    {
      label: "Europa",
      options: glovar.TICKER_INDEX_EU
    },
    {
      label: "Europa - España",
      options: glovar.TICKER_INDEX_SPAIN
    },
    {
      label: "Europa - France",
      options: glovar.TICKER_INDEX_FRANCE
    },
    {
      label: "Europa - Germany",
      options: glovar.TICKER_INDEX_GERMANY
    },
    {
      label: "UK",
      options: glovar.TICKER_INDEX_UK
    },
   
   
  ];

  const criterisOptions = [

    {
      label:i18n.t("anal_tec"),
      options:  glovar.technical_criteria
    },
    {
      label: i18n.t("anal_fun"),
      options:  glovar.fundamental_criteria
    },
  ];

  //if(!isFetchingTopTraders) 
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(
      <Box backgroundColor="#FFFFFF" minHeight="36em" minWidth="100%" pb={"20px"}>         
      <SimpleGrid   columns={14} gap={0} p={0} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
          <VStack width="100%" flexDirection="column" justifyContent="center" mt={2}>
       
           <Text textAlign="center" fontSize="36"  p={3} textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("busca_tu_prox")}
           </Text>
           <Text textAlign="center" mt={0} fontSize="22" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
           {i18n.t("encuentra_los")}
           </Text>
         </VStack>
 
 
      <VStack justifyContent="center" mt={10} mb={0}>
 

 <FormControl p={0} width="90%" zIndex={2}>
      
 <Select isMulti
        placeholder={i18n.t("sel_indice")}
        value={selectedIndex}
        //onChange={handleIndex}
        //onChange={setSelectedIndex}
         onChange={e => { setDoSearch(false); setSelectedIndex(e);  }}
        options={groupedOptions}
        //options={glovar.TICKER_INDEX}
        closeMenuOnSelect={false}
      />
 </FormControl>
 
 <FormControl p={0} width="90%">
    
 <Select isMulti
        placeholder={i18n.t("crit_tec_fin")}
        marginTop="100px"
        value={selectedCriteri}
        //onChange={setSelectedCriteri}
        onChange={e => { setSelectedCriteri(e); /*console.log("###"+JSON.stringify(e)+"###");*/ glovar.MY_FILTERS_LIST=[]; } }
        options={criterisOptions}
        closeMenuOnSelect={false}
      />
 </FormControl>
 
 
 
 

 
                 
          <button className="formSignInButtonMobile"  _hover={{ cursor: "pointer" }} 
         onClick= {() =>
          {
 
           //console.log("buscar!!")
           setDoSearch(true);
           setShowSearch(true);
           glovar.REFRESH_FILTRES=true;
           glovar.MY_FILTERS_LIST=[];
           setDoRefresh(doRefresh)
           try { sendEventGA("saM",
                             selectedIndex,selectedCriteri); } catch (exx) { /*console.log("petada event:"+exx)*/}
          }
          }
          style={{ width:"90%", paddingLeft:20, paddingRight:20, marginTop:"3em", pb:20, textAlign:"center", fontSize:"large"}}>
          {i18n.t("buscar")}</button>         
          
 
  </VStack>

 
       
  {(!doSearch && showSearch)&&
    <Box rounded={0}   mt={0} p={0} mr={0} ml={0} height="maxHeight" width="200%"  background="#FFFFFF">
     
     <ShowAssets arrayTickers={selectedIndex} anotherSearch={doSearch} criteri={selectedCriteri} refreshFiltres={true}/>  
   </Box>} 
 
  {(doSearch)&&
     <Box rounded={0}   mt={0} p={0} mr={0} ml={0} height="maxHeight"  width="200%" background="#FFFFFF">  
      
      <ShowAssets arrayTickers={selectedIndex} anotherSearch={doSearch} criteri={selectedCriteri} refreshFiltres={true}/>  
   </Box>}     
       
   </GridItem>
 
      </SimpleGrid>
    </Box> 
    );
  }
  else  
  {
  return (
   

    <Box backgroundColor="#FFFFFF" minHeight="30em" width= "100%" pb={"0px"}>         
     <SimpleGrid   columns={14} gap={1} p={2} height="maxHeight" backgroundColor="#FFFFFF">
       <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
         <VStack width="100%" flexDirection="column" justifyContent="center" mt={8}>
      
          <Text textAlign="center" mt={5} fontSize="42" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
          {i18n.t("busca_tu_prox")}
          </Text>
          <Text textAlign="center" mt={0} fontSize="26" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("encuentra_los")}    
          </Text>
        </VStack>


     <HStack justifyContent="center" mt={20} mb={20}>

<HStack width="100%" justifyContent="center" mt={0.5}>
<FormControl p={0} width="16%" zIndex={2}>
     
<Select isMulti
       placeholder={i18n.t("sel_indice")}
       value={selectedIndex}
       //onChange={handleIndex}
       //onChange={setSelectedIndex}
        onChange={e => { setDoSearch(false); setSelectedIndex(e);  }}
       options={groupedOptions}
       //options={glovar.TICKER_INDEX}
       closeMenuOnSelect={false}
     />
</FormControl>

<FormControl p={0} width="25%">
   
<Select isMulti
       placeholder={i18n.t("crit_tec_fin")}
       value={selectedCriteri}
       //onChange={setSelectedCriteri}
       onChange={e => { setSelectedCriteri(e);  glovar.MY_FILTERS_LIST=[]; } }
       options={criterisOptions}
       closeMenuOnSelect={false}
     />
</FormControl>






 <Box>
                
         <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
        onClick= {() =>
         {

          //console.log("buscar!!")
          setDoSearch(true);
          setShowSearch(true);
          glovar.REFRESH_FILTRES=true;
          glovar.MY_FILTERS_LIST=[];
          setDoRefresh(doRefresh)
          try { sendEventGA("saD",
                             selectedIndex,selectedCriteri); } catch (exx) { /*console.log("petada event:"+exx)*/}
         }
         }
         style={{ paddingLeft:20, paddingRight:20, textAlign:"center", fontSize:"large", marginLeft:5}}>
         {i18n.t("buscar")}</button>         
         </Box>

 </HStack>
 </HStack>

      
 {(!doSearch && showSearch)&&
   <Box rounded={10}   mt={0} p={0} mr={12} ml={12} height="maxHeight" background="#FFFFFF">
    
    <ShowAssets arrayTickers={selectedIndex} anotherSearch={doSearch} criteri={selectedCriteri} refreshFiltres={true}/>  
  </Box>} 

 {(doSearch)&&
    <Box rounded={10}   mt={0} p={0} mr={12} ml={12} height="maxHeight" background="#FFFFFF">  
     
     <ShowAssets arrayTickers={selectedIndex} anotherSearch={doSearch} criteri={selectedCriteri} refreshFiltres={true}/>  
  </Box>}     
      
  </GridItem>

     </SimpleGrid>


   


     <SimpleGrid columns={14} gap={1} p={2} mt={-1} backgroundColor="#FFFFFF" >



<GridItem colSpan={8} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    


         
 
</GridItem>
<GridItem colSpan={0} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0} >


  </GridItem>
</SimpleGrid> 
   </Box> 
 
  );
}
}
